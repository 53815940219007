import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import Multiselect from "vue-multiselect";
import { IBranch } from "@/models/IBranch";
import BranchService from "@/services/BranchService";
import ClientPackageService from "@/services/ClientPakcageService";
import { Watch } from "vue-property-decorator";
import { IClientPackage } from "@/models/IClientPackage";
import VuePassword from 'vue-password';
import moment from "moment";
@Options({
  components: {
    AdminLayout,
    Multiselect,
    VuePassword,
  },
})
export default class ClientPackage extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  userId = 0;
  totalItems = 0;
  current_page = 1;
  search: string = "";
  bracnhes: IBranch[] = [];
  startPrice = 500;
  packages: any = [];
  password: string = ""
  strength = 0
  branch: any
  package: IClientPackage = {
    clientPackageId: 0,
    clientPackageName: "",
    clientPackagePrice: 0,
    branchId:0,
    clientId: 1,
    isActive: false,
    otherInfo1: "",
    userId: 0,
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  async created() {
    var result = await ClientPackageService.getListofPackages(1);
    console.log(result);
    if (result.items) {
      this.packages = result.items;
      this.bracnhes = await BranchService.getAllBranch();
      this.totalItems = result.totalPages;
      this.isloaded = true;
    }

    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;
    this.package.userId = this.userId;
  }

  handleChangePage(page) {
    this.table.page = page - 1;
  }

  async setTable(pagenumber: number) {
    this.packages = [];
    var result = await ClientPackageService.getListofPackages(
      pagenumber,
      this.search
    );
    this.packages = result.items;
  }

  @Watch("packages")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  async handleSearch(searching) {
    if (searching.length > 3) {
      this.search = searching;
      var result = await ClientPackageService.getListofPackages(
        1,
        this.search
      );
      this.packages = result.items;
    } else if (searching.length == 0) {
      var result = await ClientPackageService.getListofPackages(1);
      this.packages = result.items;
    }
  }

  onBack() {
    this.active = !this.active;
  }

  async onOpen() {
    console.log(this.bracnhes);
    this.active = !this.active;
  }

  async onEdit(packageId: number) {
    this.package = await ClientPackageService.getPackageById(packageId);
    this.active = !this.active;
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;
    this.package.userId = this.userId;
    this.package.branchId = this.branch.branchId;



    ClientPackageService.savePackage(this.package).then(
      (data) => {
        this.loading = false;
        this.setTable(1);
        (this as any).$vs.notify({
          title: "New Pakcage",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        if (this.package.clientPackageId == 0) {
          this.package = {
            clientPackageId: 0,
            clientPackageName: "",
            clientPackagePrice: 0,
            branchId:0,
            clientId: 1,
            isActive: false,
            otherInfo1: "",
            userId: 0,
          };
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Package",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
